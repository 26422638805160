<template>
  <div class="content">
    <a-page-header style="padding: 0" title="On Duty Task">
      <template #extra>
        <a-button key="1" type="primary" @click="shownewModal = true">Add On Duty Task</a-button>
      </template>
    </a-page-header>


    <a-modal v-model:visible="shownewModal" title="New Task" :maskClosable="false" :keyboard="false" centered
      @ok="shownewModal = false" @cancel="handelCancelCreate">

      <a-form :label-col="{ style: { width: '100px', } }">
        <a-form-item label="Title" v-bind="validateInfos.title">
          <a-input v-model:value.trim="newTask.title" @blur="newTask.title = newTask.title.trim()" />
        </a-form-item>
        <a-form-item label="Description" v-bind="validateInfos.description">
          <a-input v-model:value.trim="newTask.description" @blur="newTask.description = newTask.description.trim()" />
        </a-form-item>
        <a-form-item label="Trigger Interval">
          <a-space>
            <a-select v-model:value="newTask.interval">
              <a-select-option v-for="(item ) in 7" :key="item" :value="item">{{ item }}</a-select-option>

            </a-select>
            Day
            <!-- <a-select v-model:value="newTask.time">
              <a-select-option value="day">Day</a-select-option>
              <a-select-option value="hour">Hour</a-select-option>
            </a-select> -->
          </a-space>
        </a-form-item>
        <a-form-item label="Trigger Time">
          <a-space>
            <a-select v-model:value="newTask.oclock">
              <a-select-option v-for="(item ) in 24" :key="item - 1" :value="item - 1">{{ item - 1 }}</a-select-option>
            </a-select> Oclock
          </a-space>
          <!-- <a-time-picker v-model:value="newTask.oclock" valueFormat="HH:mm:ss" /> -->

        </a-form-item>

      </a-form>

      <template #footer>
        <a-button key="submit" type="primary" :loading="sendMessageLoading"
          :disabled="sendMessageLoading || !newTask.title || !newTask.description" @click="handleSubmit">Submit</a-button>
      </template>
    </a-modal>

    <a-modal v-model:visible="showUpdateModal" title="Update Task" :maskClosable="false" :keyboard="false" centered
      @ok="shownewModal = false">

      <a-form :label-col="{ style: { width: '100px', } }">
        <a-form-item label="Title" v-bind="validateInfos.title">
          <a-input v-model:value.trim="tobeEdit.title" @blur="tobeEdit.title = tobeEdit.title.trim()" />
        </a-form-item>
        <a-form-item label="Description" v-bind="validateInfos.description">
          <a-input v-model:value.trim="tobeEdit.description" @blur="tobeEdit.description = tobeEdit.description.trim()" />
        </a-form-item>
        <a-form-item label="Trigger Interval">
          <a-space>
            <a-select v-model:value="tobeEdit.interval">
              <a-select-option v-for="(item ) in 7" :key="item" :value="item">{{ item }}</a-select-option>

            </a-select>
            Day
            <!-- <a-select v-model:value="tobeEdit.time">
              <a-select-option value="day">Day</a-select-option>
              <a-select-option value="hour">Hour</a-select-option>
            </a-select> -->

          </a-space>

        </a-form-item>
        <a-form-item label="Trigger Time">
          <a-space>
            <a-select v-model:value="tobeEdit.oclock">
              <a-select-option v-for="(item ) in 24" :key="item - 1" :value="item - 1">{{ item - 1 }}</a-select-option>
              Oclock
            </a-select>
          </a-space>
          <!-- <a-time-picker v-model:value="newTask.oclock" valueFormat="HH:mm:ss" /> -->

        </a-form-item>

      </a-form>

      <template #footer>
        <a-button key="submit" type="primary" @click="handleSubmitUpdate" :loading="sendUpdateMessageLoading"
          :disabled="sendUpdateMessageLoading || !tobeEdit.title || !tobeEdit.description">Submit</a-button>
      </template>
    </a-modal>


    <a-modal v-model:visible="showUserModal" title="Update Task User" :maskClosable="false" :keyboard="false" centered
      @ok="shownewModal = false">

      <a-form :label-col="{ style: { width: '100px', } }">
        <a-form-item label="Current User">
          {{ tobeEdit.user?.name || 'Have no user!' }}
        </a-form-item>

        <a-form-item label="Users">
          <a-radio-group v-model:value="tobeEdit.onDutyUserId">
            <a-radio name="user" v-for="(item, index) in userList" :value="item.id" v>{{ item.name }}</a-radio>
          </a-radio-group>
        </a-form-item>

      </a-form>

      <template #footer>
        <a-button key="submit" type="primary" :loading="sendUpdateUserMessageLoading"
          @click="handleSubmitUpdateUser">Submit</a-button>
      </template>
    </a-modal>

    <a-table :dataSource="list" :columns="columns" :loading="listLoading" @change="tableChange" :pagination="{
      hideOnSinglePage: true,
      total: total,
      current: currentPage,
      defaultPageSize: limit,
    }" rowKey="id" class="ant-table-striped" :rowClassName="
  (record, index) => (record.status == 'LOCKED' ? 'table-striped' : null)
">


      <template #targerTime="{ text }">
        {{ text ? $filters.formatTime(text) : '' }}
      </template>

      <template #tool="{ record }">
        <a-space>
          <a-button @click="handleUpdate(record)">
            <FormOutlined />
          </a-button>
          <a-button @click="handleUpdateUser(record)">
            <UserAddOutlined />
          </a-button>
          <a-button danger @click="showDeleteConfirm(record)">
            <DeleteOutlined />
          </a-button>

        </a-space>
      </template>
    </a-table>
  </div>
</template>
  
<script>
// @ is an alias to /src
const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },

  {
    title: "Title",
    dataIndex: "title",
  },
  {
    title: "Description",
    dataIndex: "description",
    slots: { customRender: "dueDate" },
  },
  {
    title: "On Duty User Name",
    dataIndex: "onDutyUserName",
    slots: { customRender: "dueDate" },
  },
  {
    title: "Interval (Day)",
    dataIndex: "interval",
    slots: { customRender: "interval" }
  },
  {
    title: "Clock (24-hour system)",
    dataIndex: "oclock",
  },
  {
    title: "Last Trigger time",
    dataIndex: "targerTime",
    slots: { customRender: "targerTime" },
    key: 'targerTime',
  },
  {
    title: "...",
    key: "tool",
    dataIndex: "id",
    slots: { customRender: "tool" },
  },
];
import { ExclamationCircleOutlined, DeleteOutlined, FormOutlined, UserAddOutlined } from "@ant-design/icons-vue";
import { toRefs, reactive, inject, computed, createVNode } from "vue";
import { useQuery, useResult, useMutation } from "@vue/apollo-composable";
import { Modal } from "ant-design-vue";
import { Form } from 'ant-design-vue';

const useForm = Form.useForm;

export default {
  components: { DeleteOutlined, FormOutlined, UserAddOutlined },
  setup(props) {
    const { $msg, gql } = inject("$");
    const v = reactive({
      limit: 20,
      total: 0,
      currentPage: 1,
      page: 1,
      shownewModal: false,
      loading: false,
      newTask: { title: '', description: '', number: 1, time: "day", oclock: 10, interval: 1 },
      showUpdateModal: false,
      tobeEdit: {},
      showUserModal: false,
    });

    const QL = gql`
        query ($page: Int, $limit: Int) {
          onDutyTasks(page: $page, limit: $limit) {
            items {
             id
             title
             description
             interval
             oclock
             onDutyUserId
             onDutyUserName
             creatorId
             targerTime
             createTime
             updateTime
            }
            total
          }
        }
      `;
    const {
      result: onDutyTasks,
      onResult,
      loading: listLoading,
      onError,
      refetch,
    } = useQuery(QL, () => ({ limit: 99 }), {
      fetchPolicy: "network-only",
    });

    const list = useResult(onDutyTasks, [], (data) => data.onDutyTasks.items);

    onError((error) => {
      let { networkError, graphQLErrors } = error;
      if (networkError) {
        $msg.error("Nerwork Error");
      }
      if (graphQLErrors) {
        $msg.error(graphQLErrors[0].message);
      }
    });

    const plainOptions = computed(() => {
      let olist = list.value.filter((e) => e.canLogin);
      return olist.map((e) => ({
        value: e.id,
        label: e.name,
      }));
    });

    const MUTATE_QL = gql`
        mutation ($title: String!, $description: String!, $interval: Int!, $oclock: Int!) {
          createOnDutyTask(title: $title, description: $description, interval: $interval, oclock: $oclock)
        }
      `;

    const {
      mutate: sendMessage,
      loading: sendMessageLoading,
      onDone: onSendMessageDone,
      onError: onSendMessageError,
    } = useMutation(MUTATE_QL);

    onSendMessageDone(() => {
      $msg.success("Added successfully");
      v.newUsers = [];
      v.shownewModal = false;
      refetch();
    });

    onSendMessageError(() => {
      $msg.error("Request error");
    });


    function tableChange(index) {
      v.page = index.current;
      v.currentPage = index.current;
    }

    const rulesRef = reactive({
      title: [{
        required: true,
        message: 'Please input title',
        max: 128,
      }],
      description: [{
        required: true,
        message: 'Please input description',
        max: 128,
      }],
    })

    const { resetFields, validate, validateInfos } = useForm(v.newTask, rulesRef, {
      //  onValidate: (...args) => console.log(...args),
    });


    function handleSubmit() {
      let { title, description, number, oclock, interval } = v.newTask
      // let interval = number
      sendMessage({
        title,
        description,
        interval,
        oclock,
      })
    }

    const DEL_MUTATE_QL = gql`
        mutation ($id: Int!) {
          deleteOnDutyTask(id: $id)
        }
      `;


    const {
      mutate: senDelMessage,
      loading: sendDelMessageLoading,
      onDone: onSendDelMessageDone,
      onError: onSendDelMessageError,
    } = useMutation(DEL_MUTATE_QL);

    onSendDelMessageDone(() => {
      $msg.success("Remove successfully");
      refetch();
    });

    onSendDelMessageError(() => {
      $msg.error("Remove error");
    })

    const showDeleteConfirm = (item) => {
      Modal.confirm({
        title: () => `Are you sure delete this task (${item.title})?`,
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => "Cannot recover after deletion",
        okText: () => "Yes",
        okType: "danger",
        cancelText: () => "No",
        onOk() {
          senDelMessage({ id: item.id });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    };


    function handleUpdate(item) {
      v.tobeEdit = JSON.parse(JSON.stringify(item))
      v.showUpdateModal = true
    }


    const PUT_MUTATE_QL = gql`
        mutation ($id: Int! $title: String!, $description: String!, $interval: Int!, $oclock: Int!) {
          updateOnDutyTask(id: $id, title: $title, description: $description, interval: $interval, oclock: $oclock)
        }
      `;

    const {
      mutate: sendUpdateMessage,
      loading: sendUpdateMessageLoading,
      onDone: onSendUpdateMessageDone,
      onError: onSendUpdateMessageError,
    } = useMutation(PUT_MUTATE_QL);

    onSendUpdateMessageDone(() => {
      $msg.success("Update successfully");
      v.showUpdateModal = false
      refetch();
    });

    function handleSubmitUpdate() {

      let { id, title, description, number, oclock, interval } = v.tobeEdit
      sendUpdateMessage({
        id,
        title,
        description,
        interval,
        oclock,
      })
    }


    function handleUpdateUser(item) {
      v.tobeEdit = JSON.parse(JSON.stringify(item))
      v.tobeEdit.user = userList.value.find(e => item.onDutyUserId == e.id)
      v.showUserModal = true
    }


    const USERQL = gql`
      query ($page: Int, $limit: Int) {
        users(page: $page, limit: $limit, committer: false) {
          items {
            username
            name
            id
            canLogin
            isAdmin
          }
          total
        }
      }
    `;
    const {
      result: committers,
      onResult: onUserResult,
      loading,
      onError: onFetchUserError,
      refetch: refetchUser,
    } = useQuery(USERQL, () => ({ limit: 99 }), {
      fetchPolicy: "network-only",
    });

    const userList = useResult(committers, [], (data) => data.users.items.filter((e) => e.canLogin));

    const USER_MUTATE_QL = gql`
        mutation ($id: Int! $onDutyUserId: Int!) {
          updateOnDutyTaskUser(id: $id, onDutyUserId: $onDutyUserId)
        }
      `;


    const {
      mutate: sendUpdateUserMessage,
      loading: sendUpdateUserMessageLoading,
      onDone: onSendUpdateUserMessageDone,
      onError: onSendUpdateUserMessageError,
    } = useMutation(USER_MUTATE_QL);

    onSendUpdateUserMessageDone(() => {
      $msg.success("Update user successfully");
      v.showUserModal = false
      refetch();
    });

    function handleSubmitUpdateUser() {

      let { id, onDutyUserId } = v.tobeEdit
      sendUpdateUserMessage({
        id,
        onDutyUserId,
      })
    }

    function handelCancelCreate(params) {
      v.newTask = { title: '', description: '', number: 1, time: "day", oclock: 10, interval: 1 }
    }


    return {
      ...toRefs(v),
      columns,
      tableChange,
      plainOptions,
      list,
      listLoading,
      sendMessage,
      sendMessageLoading,
      showDeleteConfirm,
      validateInfos,
      handleSubmit,
      handleUpdate,
      handleSubmitUpdate,
      handleUpdateUser,
      userList, handleSubmitUpdateUser, sendUpdateUserMessageLoading,
      sendUpdateMessageLoading, handelCancelCreate
    };
  },
};
</script>
  
<style lang="scss" scoped></style>
  